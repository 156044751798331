import * as React from 'react';

import WpApi from './wpapi'
import StepAspect from "./StepAspect"
import FilteredChoices from './filtered_choices'
import Expander_helper from './Expander_helper'

interface MyProps {
    post_id: string,
    tup: any,
    bptup?:any,  // buildingpart : when called by Level2Buildingpart
    options?:any,
    l2cluster:any,
    permission:any,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}

type MyState = {
    assigned_to:string,
    assigned_to_choices: any,
    is_loading:boolean
};

interface cb_after_vote {
    (r:any): void;
}

class HdaStep extends React.PureComponent<MyProps,MyState> {
    
    private callback_after_vote :cb_after_vote = (x)=>{ console.log("dummy")}



    constructor(props: MyProps) {
        super(props)
	this.handle_cb = this.handle_cb.bind(this)
	this.handle_info_click = this.handle_info_click.bind(this)
	this.handle_select_assigned_to = this.handle_select_assigned_to.bind(this)

	this.state = {
	    assigned_to:'',
	    is_loading:false,
	    assigned_to_choices:{}
	}
    }
    get_step_perm(name:string){
	let perm = this.props.permission.action
	let zwperm = this.props.permission.zwadvies
	if(name === "zw-advies"){
	    return {
		huidig: zwperm.huidig,
		voorstel: zwperm.voorstel,
		debug: 'zwadvies-step'
	    }
	}else{
	    return {
		huidig: perm.huidig,
		voorstel: perm.voorstel,
		debug: 'default step'
	    }
	}
    }

    componentDidMount(){
	if(this.is_assignable_to_coach()){
	    let atup = this.props.tup
    	    if(atup.assignable !== undefined){
		console.log(' assignable..')
		this.setState({
		    assigned_to: atup.assignable.value
		})
	    }
	}
	if(this.is_assignable_to_advisor()){
	    if(this.props.bptup !== undefined && this.props.bptup.slug !== undefined){
		if(this.props.bptup.adviseur !== undefined){
		    let zw = this.props.bptup.adviseur
		    this.setState({
			assigned_to: zw.huidig.row.value
		    })
		}
	    }else{
		// level-1
		let atup = this.props.tup
		console.log('mount met level1' + atup.name)
		if(atup.adviseur !== undefined){
		    this.setState({
			assigned_to: atup.adviseur.value
		    })
		}
	    }
	}
    }
    
    /*
     *  Post een HD-attr  "assign-to person"
     *    - advisor:  gekozen adviseur bij diverse Advies-type blokken (met class with-adviseur)
     *    - coach:   gekozeh coach, bij HD-block voor aanvraag hulp van een Coach
     * 
     */
    handle_select_assigned_to(e:any){
	console.log('selected assignted-to')
	let acfkey = null
	if(this.is_assignable_to_advisor()){
	    acfkey = this.props.tup.adviseur.key
	}else if(this.is_assignable_to_coach()){
	    acfkey = this.props.tup.assignable.key
	}

	let slug = e.target.value
	console.log(`changed dropdown to: ${slug}`)
	let bp = this.props.bptup === undefined ? '' : this.props.bptup.slug
	let d = {
	    post_id : this.props.post_id,
	    votetype: 'choice',
	    value: slug,
	    level2:this.props.l2cluster.name,
	    bouwdeel: bp,
	    aspect: 'huidig',
	    acf_key: acfkey,
	    is_final_field: "false",
	    trigger_recalc: "false",
	}
	console.log('set is loading true')
	this.setState({ is_loading:true})
	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.setState({
		is_loading:false,
		assigned_to:r.winner
	    })
	    if(this.is_assignable_to_advisor()){
	       	this.props.cb_parent({'cmd':'update-adviseur','value':r.winner},()=>{})
	    }
	})
    }
    
    // send signal to parent ; to open the vote-grid
    // pass-on the signal from StepAspect
    handle_cb(e:any,cb:(r:any)=>void){
	this.callback_after_vote = cb;
	console.log("handle-cb")
	/*
	let label_txt = this.props.tup.label
	let slab = this.props.tup.section_label
	e.label = label_txt + ' ' + slab
	*/
	if(e.cmd === "choice-vote"){
	    if(this.is_assignable_to_advisor()){
		e.history_popup.assignable = this.props.tup.adviseur
		e.history_popup.assignable_choices = this.get_options_adviseurs_map()
	    }
	    else if(this.is_assignable_to_coach()){
		e.history_popup.assignable_choices = this.get_options_coaches_map()
		e.history_popup.assignable = this.props.tup.assignable
	    }
	}
	this.props.cb_parent(e,(r:any)=>{
	    console.log("received:")
	    console.log(r)
	    // reverse signal to StepAspect
	    this.callback_after_vote(r)
	})
    }
    public handle_info_click(){
	this.props.cb_parent({
	    cmd: 'open-info',
	    txt: this.props.tup.instructions
	},()=>{})
    }
    public render_instr(){
	if(this.props.tup.instructions){
	    return (<span className="cf-icon-btn"
		    data-balloon="Toon toelichting" data-balloon-pos="up"
		    onClick={this.handle_info_click}>
		<i className="bb-icon-rl bb-icon-info"/>
		</span>
		)
	}
    }
    is_assignable_to_coach(){
	if(this.props.tup.huidig.value === ""){
	    return false
	}
	if(this.props.tup.classes.includes('assignable-to-coach')){
	    return true
	}
    }
    is_assignable_to_advisor(){
	if(this.props.tup.huidig.value === ""){
	    return false
	}
	if(this.props.tup.classes.includes('with-adviseur')){
	    if(this.props.tup.adviseur === undefined){
		return false
	    }
	    if(this.props.tup.adviseur.key === ""){
		return false
	    }
	    return true
	}
    }
    /*
     * return the label for current assignee value
     */
    get_assigned_label(slug:string){
	let choices = []
	if(this.is_assignable_to_advisor()){
	    choices = this.get_options_adviseurs()
	}
	else if(this.is_assignable_to_coach()){
	    choices = this.get_options_coaches()
	}
	let res = choices.filter((x:any) =>  x.name === slug)
	if(res.length === 1){
	    return res[0].label
	}
	return slug
    }
    get_options_adviseurs_map(){
	let ret :{ [k:string]:string}= {}
	for(var x of this.get_options_adviseurs()){
	    ret[x.name] = x.label
	}
	return ret

    }
    get_options_adviseurs(){
	return this.props.options.adviseur.buttons_huidig.list
    }
    get_options_coaches_map(){
	let ret :{ [k:string]:string}= {}
	for(var x of this.get_options_coaches()){
	    ret[x.name] = x.label
	}
	return ret
    }
    get_options_coaches(){
	let choices1 = this.props.options.coaches
	if(choices1 === undefined) {return []}
	let choices = choices1.map((x:any) => {
	    return {
		'name' : String(x.id),
		'label' : x.name
	    }
	})
	return choices
    }
    render_coach_dropdown(){
	let prefix = "Toegewezen coach:"
	let choices = this.get_options_coaches()
	if(Object.entries(choices).length ===0 ){
	    return (<div>ERROR: no select-options were found </div>)
	}
	return this.render_aux_option(prefix,choices)
    }
    render_adviseur_dropdown(){
	// supplier : de huidige toegewezen adviseur / leverancier / ..
	let choices1 = this.get_options_adviseurs()
	let prefix = "Adviseur:"
	
	if(this.props.tup.name  === "zw-advies"){
	    prefix = "Toegewezen adviseur"
	}
	if(Object.entries(choices1).length ===0 ){
	    return null
	}
	let block_classes = this.props.tup.adviseur.classes
	let choices = choices1.filter((x:any) => {
	    for(var c of x.classes){
		if(block_classes.includes(c)){
		    return true
		}
	    }
	    return false
	})


	return this.render_aux_option(prefix,choices)
    }
    render_aux_option(prefix:string,choices:[]){
	let assigned_to = this.state.assigned_to
	let perm = this.props.permission
	let permtup = null
	if(this.is_assignable_to_advisor()){
	    // perm check
	    if(this.props.tup.name === "zw-advise"){
		permtup = perm.assign_zw_advise
	    }else{
		permtup = perm.assign_extern_advise
	    }
	}
	else if(this.is_assignable_to_coach()){
	    permtup = perm.assign_coach
	}
	let has_edit_perm = false
	if(permtup !== null){
	    has_edit_perm = permtup.edit.current_user
	}
	let showing_dropdown = has_edit_perm
       	if(this.props.tup.huidig.value === ""){
	    showing_dropdown = false
	}


	if(!showing_dropdown){
	    // show readonly value
	    if(assigned_to === null || assigned_to === ""){
		return null
	    }
	    let adv_label = this.get_assigned_label(assigned_to)
	    return (
		<div className="aux-option">
		    <div className="label">{prefix}</div>
		    {adv_label}
		</div>
	    )
	}
	let assigned_v = assigned_to === null ? '' : assigned_to
	// show dropdown
	return (
	    <div className="aux-option">
		<div className="label">{prefix}</div>
		<select style={{height:'unset'}} onChange={this.handle_select_assigned_to} value={assigned_v}>
    		    <option value="">-kies-</option>
    		    {choices.map((x:any,i:number) => {
    			return (<option key={i} value={x.name}>{x.label}</option>)
    		    })}
    		</select>
	    </div>
	)
    }
    is_expanded(){
	if(this.props.options.config !== undefined){
	    let conf = this.props.options.config
	    if(conf.level2 === null){
		return conf.block === this.props.tup.key
	    }
	    let l2 = this.props.l2cluster.name
	    if( conf.level2 === l2){
		return conf.block === this.props.tup.key
	    }
	}
	return false
    }


    public render(){
	let label_txt = this.props.tup.label
	let label_txt2 = this.props.tup.label
	let parent_lab = this.props.tup.section_label

	if(this.props.l2cluster && this.props.l2cluster.label){
	    parent_lab = this.props.l2cluster.label
	}
	    

	let m = /^([^{]+){parent}/.exec(this.props.tup.label)


	if(m){
	    let lab1 = m[1].trim()
	    label_txt = (<span>{lab1}: <i>{parent_lab}</i></span>)
	    label_txt2 = (<span>{lab1} <i>{parent_lab}</i></span>)
	}
	let classes = this.is_expanded() ? "highlight"  : "collapsed"


	if(this.props.tup.label === ''){
	    return this.render_body("block",label_txt2)
	}else{
	    let exp = new Expander_helper();
	    let force_expanded = false
	    if(this.props.options !== undefined && this.props.options.context !== undefined){
		force_expanded = this.props.options.context === "adviesaanvragen"
	    }

	    return force_expanded ? (
		<div className="step without-expand" acf-key={this.props.tup.key}>
		    <div className="header" >

		        <div className="label">{label_txt} </div>
			{this.render_instr()}
		    </div>
		    {this.render_body("block",label_txt2)}
		</div>
	    ) : (
		<div className={"step with-expand "+classes} acf-key={this.props.tup.key}>
		    <div className="header"  onClick={exp.toggle_expand}>
		        <div className="pre-icon"></div>
		        <div className="label">{label_txt} </div>
			{this.render_instr()}
		    </div>
		    {this.render_body("body",label_txt2)}
		</div>
	    )
	    
	}
    }

    render_aspect(asp:string,label:string){
	if(asp === "voorstel" && this.props.tup.voorstel === undefined){
	    return null
	}
	let perm = this.get_step_perm(this.props.tup.name)
	let p = asp === 'huidig' ? perm.huidig : perm.voorstel
	let atup = this.props.tup[asp]
	if(!atup.active){
	    return null
	}
	if(atup === undefined){
	    return null
	}
	return (
	    <StepAspect
		post_id={this.props.post_id}
		label={label}
		l2cluster={this.props.l2cluster}
		stup={this.props.tup}
		bptup={this.props.bptup}
		permission={p}
		aspect={asp} tup={atup} acfkey={this.props.tup.key}
		cb_parent={this.handle_cb} />
	)
	
    }
    render_body(class_name:string,label_txt:string){
	let columns = (
	    <div className="aspects two-columns">
		{this.render_aspect('huidig',label_txt)}
	    	{this.render_aspect('voorstel',label_txt)}
    	    </div>
	)
	if(this.props.options.context === "adviesaanvragen"){
	    if(this.props.options.adviestype === "zw-advies"){
		columns = (
		    <div className="aspects one-column">
		    {this.render_aspect('huidig',label_txt)}
		    </div>
		)
	    }
	}
	return (
	    <div className={class_name}>
		{this.is_assignable_to_coach() ? this.render_coach_dropdown() : null}
		{this.is_assignable_to_advisor() ? this.render_adviseur_dropdown() : null}
		{columns}
	    </div>
	)
    }
}
export default HdaStep;
