import * as React from 'react';

import WpApi from '../wpapi'
import Loader from '../Loader'
import {advise_type, render_advise} from '../Advise'

import Loco from '../Loco'

import './css/HistoryGrid.css'

interface MyProps {
    post_id: string,
}


type MyState = {
    loading:boolean,
    choices: {[name:string]:string},
    assignable_choices: {[name:string]:string},
    post_id:number,
    votetype:string,
    data: Array<any>
}
class HistoryGrid extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    loading:false,
	    votetype:'',
	    choices:{},
	    assignable_choices:{},
	    post_id:-1,
	    data: []
	}
    }

    public init(e:any){
	//aspect:string,tup:any){
	console.log("init")
	console.log(e)
	let post_id = this.props.post_id
	let post_id_num = Number(post_id)
	if(isNaN(post_id_num)){
	    console.log(` pickup post-id from e  ( ${e.post_id} )`)
	    post_id = e.post_id
	}
	this.setState({
	    loading:true
	})
	if(e.assignable_choices !== undefined){
	    this.setState({ assignable_choices: e.assignable_choices})
	}
	if(e.choices !== undefined){
	    this.setState({ choices: e.choices})
	}
	if(e.votetype !== undefined){
	    this.setState({ votetype: e.votetype})
	}
	if(e.tup !== undefined){
	    if(e.tup.cftype === "hda-bouwdelen-stap" && e.aspect === "huidig"){
		this.setState({ choices: e.tup.opt_lists.oordeel})
	    }
	}

	let d :{[name:string]:string}= {
	    post_id: post_id,
	    acf_key: e.acf_key,
	    aspect: e.aspect
	}
	if(e.assignable !== undefined){
	    d.acf_key2 = e.assignable.key
	}

	if(e.bouwdeel !== undefined && e.bouwdeel !== null){
	    d.bouwdeel = e.bouwdeel
	}
	if(e.level2 !== undefined){
	    d.level2 = e.level2
	}
	if(e.attr_id !== undefined ){
	    d.on_attr_id = e.attr_id
	}
	WpApi.do_get('/vote-history', d, (r:any) => {
	    console.log(r)
	    this.got_data(r.data)
	});

    }
    got_data(r:Array<any>){
	// create a map of attr, so we can follow attr_ref
	var values : { [string: string]: any } = {}
	r.forEach((x,i)=>{
	    values[x.id] = x
	    x.num = i
	})
	let filtered = r
	if(this.state.votetype === "comment"){
	    filtered = r.filter((x:any) => {
		return x.attr_type === "comment"
	    })
	}
	var data = filtered.map((x:any) => {
	    if(x.attr_type === "advise"){
		let adv :advise_type = JSON.parse(x.value)
		x.value = render_advise(adv,true)
	    }
	    else if(x.attr_type === "comment"){
		if(x.value.length > 100){
		    x.value = x.value.substring(0,100) + ".."
		}
	    }
	    else if(x.attr_type === "attr_ref"){
		let a = values[x.linked_attr]
		if(a !== undefined){
		    let atype = a.attr_type + '_attr'
		    let v = Loco.tr(atype) + ' #' + a.num
		    if(v.length > 100){
			v = v.substring(0,100) + ".."
		    }
		    x.value = v
		}
	    }
	    else if(x.attr_type === "choice"){
		if(x.value in this.state.choices){
		    x.value = this.state.choices[x.value]
		}
		if(x.value in this.state.assignable_choices){
		    x.value = this.state.assignable_choices[x.value]
		}

	    }
	    return x
	})
	this.setState({loading:false,data: data})
    }
    render_row(tup:any,i:number){
	let atype = Loco.tr(tup.attr_type+'_attr')
	if(tup.field_name == 'leverancier'){
	    atype = 'Leverancier'
	}else if(tup.field_name == 'adviseur'){
	    atype = 'Toegewezen adviseur'
	}else if(tup.field_name == 'assignable-to-coach'){
	    atype = 'Toegewezen coach'
	}
	return (
	    <div className="row" key={i}>
		<div>{tup.num}</div>
		<div>{atype}</div>
		<div>{tup.localtime}</div>
		<div>{tup.display_name}</div>
		<div>{tup.value}</div>
		<div>{Loco.tr(tup.status+'_attr')}</div>
	    </div>
	)
    }
    render(){
	var msg = ''
	if(this.state.data.length === 0){
	    msg = 'De historie is nog leeg'
	}
	return (

	    
	    <div className="history-grid">
		
		<div className="header row" key="header">
		  <div>#</div>
		<div>soort</div>
		<div>datum + tijd</div>
		<div>gebruiker</div>
		<div>waarde</div>
		<div>actief of archief </div>
		</div>
		
		{this.state.loading ? <Loader /> : msg}
	    
	    {this.state.data.map((tup:any,i:number) => this.render_row(tup,i))}

            </div>

	)
    }
}
export default HistoryGrid;
